import React, { useEffect, useState, useRef  } from 'react';
import { PanZoom } from 'react-easy-panzoom';

const App = () => {
  const [bounds, setBounds] = useState([
    [10.78043594, 106.6835783],
    [10.78043594, 106.6927229],
    [10.78941906, 106.6835783],
    [10.78941906 , 106.6927229],
  ]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [markerPositionInImage, setMarkerPositionInImage] = useState({});
  const [zoomScale, setZoomScale] = useState(1); // State lưu zoom scale
  const panZoomRef = useRef(); // Tạo ref để điều khiển PanZoom

  const markerSize = 8; // Kích thước biểu tượng

  const [location, setLocation] = useState({ lat: 10.7849275, lon: 106.6881506 });
  const [accuracy , setAccuracy] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const [regionInfo, setRegionInfo] = useState(null); // State lưu thông tin vùng
  const [regionImage, setRegionImage] = useState(null); // State lưu hình ảnh của vùng

  useEffect(() => {
    if (navigator.geolocation) {
      const getPosition = () => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation({
              lat: position.coords.latitude,
              lon: position.coords.longitude,
            });
            setAccuracy(position.coords.accuracy);
          },
          (error) => {
            // Xử lý lỗi
            setErrorMessage(error.message);
          },
          {
            enableHighAccuracy: true,
            timeout: 1000,
            maximumAge: 1000,
          }
        );
      };
  
      // Lấy vị trí ngay lập tức
      getPosition();
  
      // Cập nhật vị trí mỗi 1 giây
      const intervalId = setInterval(getPosition, 1000);
  
      // Hủy interval và theo dõi khi component bị unmount
      return () => {
        clearInterval(intervalId);
      };
    } else {
      setErrorMessage('Trình duyệt của bạn không hỗ trợ Geolocation');
    }
  }, []);

  const handleBoundsInput = (e) => {
    const { name, value } = e.target;
    const newBounds = [...bounds];
    const [corner, coord] = name.split('-');

    if (corner === 'topLeft') {
      newBounds[0][0] = coord === 'lat' ? parseFloat(value) : newBounds[0][0];
      newBounds[0][1] = coord === 'lon' ? parseFloat(value) : newBounds[0][1];
    } else if (corner === 'topRight') {
      newBounds[1][0] = coord === 'lat' ? parseFloat(value) : newBounds[1][0];
      newBounds[1][1] = coord === 'lon' ? parseFloat(value) : newBounds[1][1];
    } else if (corner === 'bottomLeft') {
      newBounds[2][0] = coord === 'lat' ? parseFloat(value) : newBounds[2][0];
      newBounds[2][1] = coord === 'lon' ? parseFloat(value) : newBounds[2][1];
    } else if (corner === 'bottomRight') {
      newBounds[3][0] = coord === 'lat' ? parseFloat(value) : newBounds[3][0];
      newBounds[3][1] = coord === 'lon' ? parseFloat(value) : newBounds[3][1];
    }

    setBounds(newBounds);
  };

   const handleMarkerInput = (e) => {
    const { name, value } = e.target;
    setLocation((prevLocation) => ({
      ...prevLocation,
      [name]: parseFloat(value),
    }));
  };

  useEffect(() => {
    if (location && bounds.length === 4) {
      const imgWidth = 1024;
      const imgHeight = 1024;

      const latMin = bounds[0][0];
      const latMax = bounds[2][0];
      const lonMin = bounds[0][1];
      const lonMax = bounds[1][1];

      const x = ((location.lon - lonMin) / (lonMax - lonMin)) * imgWidth;
      const y = imgHeight - ((location.lat - latMin) / (latMax - latMin)) * imgHeight;

      setMarkerPositionInImage({ x, y });
    }
  }, [location, bounds]);

  const handleImageClick = (e) => {
    const imgElement = e.target;
    const rect = imgElement.getBoundingClientRect();
    const clickX = (e.clientX - rect.left) / zoomScale; // Tọa độ X của điểm chạm
    const clickY = (e.clientY - rect.top) / zoomScale; // Tọa độ Y của điểm chạm
    console.log("x: ", clickX)
    console.log("y: ", clickY)
    // Giả sử bạn có một danh sách các vùng và hình ảnh tương ứng
    const regions = {
      cungphuonghoang: {
        info: 'Cung Phụng Hoàng: Khu vực này là một cung điện theo chủ đề thần thoại, mang phong cách kiến trúc cổ kính và có một bức tượng Phụng Hoàng nổi bật, là biểu tượng của sự tái sinh và thịnh vượng.',
        image: '/images/cungphuonghoang.jpg',
      },
      khunglong: {
        info: 'Công viên Khủng Long: Khu vực này có mô hình khủng long lớn, hấp dẫn du khách với các mô hình sống động và các hoạt động giáo dục về thế giới khủng long thời tiền sử.',
        image: '/images/khunglong.jpg',
      },
      congviennuoc: {
        info: 'Khu công viên nước: Một công viên nước vui nhộn, với các đường trượt nước, hồ bơi và khu vui chơi dưới nước, lý tưởng cho các gia đình và trẻ em.',
        image: '/images/congviennuoc.jpg',
      },
      off: {
        info: null,
        image: null,
      },
      // Thêm các vùng khác nếu cần
    };

    let clickedRegion;
    if(clickX > 280 && clickX < 410 && clickY > 500 && clickY < 600) clickedRegion = 'cungphuonghoang';
    else if((clickX > 630 && clickX < 840 && clickY > 610 && clickY < 740)
          || (clickX > 620 && clickX < 730 && clickY > 480 && clickY < 570)
          || (clickX > 180 && clickX < 330 && clickY > 635 && clickY < 720)
          || (clickX > 390 && clickX < 610 && clickY > 890 && clickY < 1024)
          ) clickedRegion = 'khunglong';
    else if(clickX > 290 && clickX < 690 && clickY > 700 && clickY < 915) clickedRegion = 'congviennuoc';

    else clickedRegion = 'off'


    if (regions[clickedRegion]) {
      setRegionInfo(regions[clickedRegion]?.info);
      setRegionImage(regions[clickedRegion]?.image);
    }
  };

    // Hàm xử lý zoom thay đổi
    const handleZoomChange = (zoom) => {
      setZoomScale(zoom);
    };

      // Hàm xử lý zoom in
  const handleZoomIn = () => {
    if (panZoomRef.current) {
      panZoomRef.current.zoomIn(1); // Zoom in với mức zoom 1
    }
  };

  // Hàm xử lý zoom out
  const handleZoomOut = () => {
    if (panZoomRef.current) {
      panZoomRef.current.zoomOut(1); // Zoom out với mức zoom 1
    }
  };

    // Hàm kích hoạt chế độ toàn màn hình
    const enterFullscreen = () => {
      if (panZoomRef.current) {
        const element = panZoomRef.current.container.current; // Lấy phần tử PanZoom
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { // Firefox
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { // Chrome, Safari và Opera
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { // IE/Edge
          element.msRequestFullscreen();
        } else if (element.webkitEnterFullscreen) { // iPhone Safari
          element.webkitEnterFullscreen(); // Sử dụng API webkitEnterFullscreen cho iPhone
        } else if (typeof window !== "undefined" && window.navigator.standalone) {
          // Đối với các thiết bị iOS khi chạy từ màn hình chính (Standalone mode)
          const body = document.querySelector('body');
          if (body) {
            body.style.height = "100%";
            body.style.overflow = "hidden"; // Ẩn scroll để hiển thị toàn màn hình
          }
        }
      }
    };
  
    const formatNumber = (number, n) => {
      let formattedNumber = Number(number).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: n
    });
    return formattedNumber;
    };

  return (
    <>
      <h2 style={{ textAlign: 'center', marginTop: '8px' }}>Location Map</h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '400px',
            height: '400px',
            overflow: 'hidden',
            border: '1px solid red',
            position: 'relative',
          }}
        >
          
          {/* Khu vực điều khiển zoom */}
          <div style={{ position: 'absolute', bottom: '10px', right: '10px', zIndex: 10 }}>
            <button onTouchStart={enterFullscreen} style={{ marginRight: '20px', padding: '6px 10px' }}>Xem Toàn Màn Hình</button>
            <button onTouchStart={handleZoomIn} style={{ marginRight: '20px', padding: '6px 10px' }}>+</button>
            <button onTouchStart={handleZoomOut} style={{ padding: '6px 10px' }}>-</button>
          </div>

          <PanZoom
            boundaryRatioVertical={0.8} 
            boundaryRatioHorizontal={0.8} 
            enableBoundingBox
            onStateChange={(state) => handleZoomChange(state.scale)} // Lấy giá trị zoom hiện tại
            ref={panZoomRef} // Gắn ref để điều khiển PanZoom từ bên ngoài
            realPinch
            disableDoubleClickZoom
            minZoom={0.4}
            maxZoom={1.2}
            zoomSpeed={1.2}
          >
            <div style={{position: 'relative'}} onClick={handleImageClick}>
              <img
                style={{ objectFit: 'contain', cursor: 'pointer'}}
                src="/images/suoitien.jpg"
                alt="Map"
                onLoad={() => setImageLoaded(true)}
              />
              {imageLoaded && (
                <div
                  style={{
                    position: 'absolute',
                    left: `${markerPositionInImage.x}px`,
                    top: `${markerPositionInImage.y}px`,
                    width: `${markerSize / zoomScale}px`, // Điều chỉnh kích thước theo zoom
                    height: `${markerSize / zoomScale}px`, // Điều chỉnh kích thước theo zoom
                    borderRadius: '50%',
                    border: `${2/zoomScale}px solid #fff`,
                    backgroundColor: 'blue',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              )}
            </div>
          </PanZoom>
        </div>

        {regionInfo && (
          <div style={{ marginTop: '12px', display: 'flex', alignItems: 'center' }}>
            <img src={regionImage} alt="Hình ảnh chi tiết" style={{ width: '200px', height: '200px', marginRight: '12px',objectFit: 'contain' }} />
            <div style={{ fontSize: '18px', color: 'blue' }}>{regionInfo}</div>
          </div>
        )}

        {/* Nhập tọa độ để hiển thị marker */}
        <div>
          <h3  style={{marginBottom: '12px'}}>Vị trí hiện tại của bạn: độ lệch {formatNumber(accuracy, 0)}m</h3>
          <input type="number" name="lat" placeholder="Vĩ độ" value={location?.lat || ""} onChange={handleMarkerInput} />
          <input type="number" name="lon" placeholder="Kinh độ" value={location?.lon || ""} onChange={handleMarkerInput} />
        </div>


        {/* Nhập tọa độ các góc */}
        <div>
          <h3 style={{marginBottom: '12px'}}>Nhập tọa độ các góc của bản đồ:</h3>
          <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}} >
            <label>Góc dưới trái: </label>
            <div>
              <input type="number" name="topLeft-lat" placeholder="Vĩ độ" value={bounds[0][0] || ""} onChange={handleBoundsInput} />
              <input type="number" name="topLeft-lon" placeholder="Kinh độ" value={bounds[0][1] || ""} onChange={handleBoundsInput} />
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <label>Góc dưới phải: </label>
            <div>
              <input type="number" name="topRight-lat" placeholder="Vĩ độ" value={bounds[1][0] || ""} onChange={handleBoundsInput} />
              <input type="number" name="topRight-lon" placeholder="Kinh độ" value={bounds[1][1] || ""} onChange={handleBoundsInput} />
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <label>Góc trên trái: </label>
            <div>
              <input type="number" name="bottomLeft-lat" placeholder="Vĩ độ" value={bounds[2][0] || ""} onChange={handleBoundsInput} />
              <input type="number" name="bottomLeft-lon" placeholder="Kinh độ" value={bounds[2][1] || ""} onChange={handleBoundsInput} />
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <label>Góc trên phải: </label>
            <div>
              <input type="number" name="bottomRight-lat" placeholder="Vĩ độ" value={bounds[3][0] || ""} onChange={handleBoundsInput} />
              <input type="number" name="bottomRight-lon" placeholder="Kinh độ" value={bounds[3][1] || ""} onChange={handleBoundsInput} />
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default App;
